
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format, isWednesday } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const form = ref({});

    const farm_id = ref(0);
    const farms = ref([]);
    const farmName = computed(() => {
      const n = farms.value?.find((f: any) => f.id == farm_id.value);

      return n?.["name"];
    });

    const varieties = ref({
      data: []
    });

    const processing = ref(false);

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }
    const starting = ref(wednesday());
    const masks = ref({
      input: "YYYY-MM-DD"
    });

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    function getDates(start: any) {
      const stop = new Date(
        new Date(start).getTime() + 69 * 24 * 60 * 60 * 1000
      );
      const dates = [];
      let currentDate = moment(start);
      const stopDate = moment(stop);
      while (currentDate <= stopDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    const weeks = computed(() => {
      const dates = getDates(starting.value);
      const size = 7;

      const result = dates.reduce((group: any, item, index) => {
        const c = Math.floor(index / size);

        if (!group[c]) {
          group[c] = [];
        }

        group[c].push(item);

        return group;
      }, []);

      return result;
    });

    async function fetchPicks(f: any) {
      const res = await http.get(
        `api/forecasts?farm_id=${f}&start=${fD(
          new Date(weeks.value[0][0]),
          "yyy-MM-dd"
        )}&end=${fD(new Date(weeks.value[9][6]), "yyy-MM-dd")}&type=pick`
      );

      return res.data.data;
    }

    function fetchForecast(f: any) {
      processing.value = true;

      fetchPicks(f).then((fs: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          form.value = weeks.value.reduce((l: any, w: any) => {
            const p: any = fs.find((or: any) => or.date == w[0]);

            return {
              ...l,
              [w[0]]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected:
                      ((p?.quantities as any)[v.id] as any)?.projected || 0,
                    target: ((p?.quantities as any)[v.id] as any)?.target || 0,
                    current:
                      ((p?.quantities as any)[v.id] as any)?.current || 0,
                    comment:
                      ((p?.quantities as any)[v.id] as any)?.comment || ""
                  }
                }),
                {}
              )
            };
          }, {});

          processing.value = false;
        });
      });
    }

    function savePick() {
      http
        .post("/api/forecasts", {
          farm_id: farm_id.value,
          forecasts: form.value,
          start: fD(new Date(weeks.value[0][0]), "yyy-MM-dd"),
          end: fD(new Date(weeks.value[9][6]), "yyy-MM-dd"),
          type: "pick"
        })
        .then(res => {
          fetchForecast(farm_id.value);
        });
    }

    watch(
      () => starting.value,
      () => {
        fetchForecast(farm_id.value);
      }
    );

    onMounted(() => {
      fetchFarms();

      if (router.currentRoute.value.query?.farm_id) {
        const f = router.currentRoute.value.query?.farm_id;

        farm_id.value = Number(f);
        fetchForecast(f);
      } else {
        farm_id.value = 1;
        fetchForecast(1);
      }
    });

    return {
      fetchFarms,
      fetchForecast,
      processing,
      varieties,
      fD,
      starting,
      masks,
      getDates,
      form,
      savePick,
      weeks,
      farms,
      farm_id,
      farmName
    };
  }
});
